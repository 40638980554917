.activity {
  @apply grid grid-cols-[1fr_max-content] md:grid-cols-[calc(100%/6)_1fr_max-content] items-center md:items-start gap-2 md:gap-12 py-4 first:pt-0 last:pb-0;

  &__container {
    @apply divide-y divide-gray-3;
  }

  &__time {
    @apply w-full flex-none text-sm text-gray-2;
  }

  &__content {
    @apply col-span-2 md:col-span-1 order-first md:order-none space-y-2;

    > span:first-child {
      span {
        @apply text-gray-2 text-sm;

        svg {
          @apply text-gray fill-current;
        }
      }

      svg {
        @apply inline-block;
      }

      a {
        @apply text-secondary font-semibold hover:underline;
      }
    }

    > span:last-child:not(:only-child) {
      @apply flex items-center gap-1 text-gray-2 text-sm;

      svg {
        @apply w-3.5 h-3.5 text-gray fill-current;
      }

      a {
        @apply underline;
      }
    }
  }

  &__author {
    @apply ml-auto flex-none;
  }
}
