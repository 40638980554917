.photo {
  @apply cursor-pointer text-left focus-visible:outline-none;

  &__container {
    @apply grid grid-cols-2 md:grid-cols-5 gap-x-4 gap-y-10 md:gap-12 place-items-start;
  }

  &__img {
    @apply border-4 border-background rounded overflow-hidden aspect-[4/3];

    img {
      @apply w-full h-full object-cover;
    }
  }

  &__title {
    @apply mt-4 text-black text-lg font-semibold;
  }

  &__description {
    @apply mt-2 text-gray-2 text-sm;
  }

  &:hover &__img,
  &:focus &__img {
    @apply border-tertiary;
  }

  &:hover &__title,
  &:focus &__title {
    @apply underline;
  }

  &-modal {
    @apply pb-16 pr-16;

    &__img {
      @apply mt-8 rounded overflow-hidden;

      img {
        @apply w-full h-full object-cover;
      }
    }

    &__title {
      @apply mt-8 font-semibold text-lg text-gray-2;
    }

    &__description {
      @apply text-gray-2 text-md;
    }
  }
}
