.spinner-container {
  @apply relative cursor-wait;

  &::after {
    @apply content-[""] absolute inset-0 w-full h-full bg-white/80 z-10;
  }

  &::before {
    @apply content-[""] sticky top-[50vh] ml-[50%] md:ml-[calc(50%-4rem)] block w-6 h-6 -mt-6 rounded-full animate-spin border-4 border-l-background border-y-background border-r-secondary z-20;
  }
}
