.search {
  &__filter {
    @apply self-stretch;

    [id*="dropdown-menu"] {
      @apply gap-0 divide-y divide-background-3 [&>*]:py-4 first:[&>*]:pt-0 last:[&>*]:pb-0 [&>*]:space-y-4 cursor-auto;

      .is-empty {
        @apply pointer-events-none opacity-25;
      }
    }
  }

  &__result {
    @apply py-10 first:pt-0 last:pb-0 border-b-2 last:border-0 border-background space-y-8;
  }
}
