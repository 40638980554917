footer {
  @apply mb-16 lg:mb-0;

  .main-footer {
    @apply bg-gray-4;

    &__top {
      @apply flex flex-col lg:flex-row gap-8 container py-10;
    }

    &__down {
      @apply border-t border-black flex flex-wrap items-center gap-6 container py-6 text-white;
    }

    &__language {
      @apply absolute top-full left-0 bg-white rounded w-full;

      &-container {
        @apply relative;
      }

      &-trigger {
        @apply flex items-center gap-1 border border-white rounded py-1.5 px-2 cursor-pointer text-md font-semibold;
      }
    }

    /* overwrite default dropdown styles */
    [data-target*="dropdown"] {
      > svg {
        @apply w-4 h-4 text-white fill-current last-of-type:block last-of-type:ml-auto;
      }

      &[aria-expanded="true"] > svg:first-of-type {
        @apply block;
      }

      > span {
        @apply block text-white;
      }
    }

    /* overwrite default dropdown styles */
    [id*="dropdown-menu"] {
      @apply py-0 mx-0 w-full;

      &[aria-hidden="true"] {
        @apply md:hidden;
      }
    }
  }

  .mini-footer {
    @apply bg-black py-5;

    &__content {
      @apply container flex justify-between items-center text-white;
    }
  }
}
