.identities-modal {
  &__list {
    @apply flex flex-col gap-2 py-10;

    button {
      @apply w-full border-4 border-background rounded p-2 flex items-center justify-between cursor-pointer;

      &.is-selected {
        @apply border-transparent bg-success/10 [&_svg]:visible;
      }

      &:focus,
      &:focus-visible,
      &:hover {
        @apply border-tertiary outline-none;
      }

      svg {
        @apply w-6 h-6 text-success fill-current invisible;
      }
    }
  }
}
