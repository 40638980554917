.hero {
  @apply h-[60vh] md:h-[65vh] container px-4 md:px-24 flex flex-col justify-center items-center gap-8 md:gap-16;

  &__container {
    /* stylelint-disable-next-line number-leading-zero */
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.7) 21.88%,
        rgba(0, 0, 0, 0.46421) 60.94%,
        rgba(0, 0, 0, 0.105) 100%
      ),
      var(--hero-image);

    @apply bg-center bg-cover;
  }

  &__title {
    @apply text-center text-white text-2xl md:text-5xl font-bold;
  }
}
