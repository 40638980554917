.metric {
  @apply flex flex-col rounded border-4 border-background p-4;

  &__container {
    @apply grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-10;
  }

  h3 {
    @apply font-semibold text-lg text-gray-2;
  }

  p {
    @apply font-normal text-sm text-gray-2;
  }

  a {
    @apply mt-auto self-end font-normal text-sm text-secondary;
  }

  svg {
    @apply overflow-visible rounded bg-background my-8;
  }

  .area {
    @apply fill-primary/5;
  }

  .line {
    @apply stroke-primary/20 stroke-2;

    fill: none;
  }

  .circle {
    @apply fill-primary/20;
  }

  .tick {
    @apply opacity-25;
  }

  .dashed {
    stroke-dasharray: 1, 3;
  }

  .sum {
    @apply font-bold text-3xl;
  }
}

[id$="metric-tooltip"] {
  @apply absolute overflow-visible translate-x-[-50%] shadow-[0_0_20px_0] shadow-gray bg-white;

  > * {
    @apply p-2 text-sm relative;

    &::after,
    &::before {
      @apply top-full left-1/2 content-[""] h-0 w-0 absolute pointer-events-none border-transparent;
    }

    &::after {
      @apply top-[calc(100%_-_1px)] -ml-[10px] border-[10px] border-t-white;
    }

    &::before {
      @apply -ml-[11px] border-[11px];
    }
  }
}
