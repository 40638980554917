[role="tooltip"] {
  @apply absolute bg-black z-10 px-4 py-2 w-max max-w-xs rounded text-left text-white;

  & > * {
    @apply relative z-20;
  }

  &[aria-hidden="false"] {
    animation-name: fadeIn;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
  }

  &[aria-hidden="true"] {
    animation-name: fadeOut;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      visibility: hidden;
    }

    to {
      opacity: 1;
      visibility: visible;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
      visibility: visible;
    }

    to {
      opacity: 0;
      visibility: hidden;
    }
  }

  /*
    NOTE: the calculated value is the sum of the arrow offset position plus the half of the arrow size:
    - offset position: 20%
    - arrow size: 16px
  */
  --arrow-offset: 20%;
  --arrow-size: 16px;
  --arrow-visible-size: var(--arrow-size) * 0.5;
  --arrow-margin: var(--arrow-visible-size) * 1.4142135623730951; // due to the rotation, the margin is SQRT2 times the visible size

  &.top {
    @apply -translate-x-[calc(100%-var(--arrow-offset))] -translate-y-[calc(100%+var(--arrow-margin))] before:content-[''] before:absolute before:-z-10 before:right-[calc(var(--arrow-offset)-var(--arrow-visible-size))] before:-bottom-[var(--arrow-visible-size)] before:w-[var(--arrow-size)] before:h-[var(--arrow-size)] before:rotate-45 before:bg-black before:rounded-br;
  }

  &.right {
    @apply translate-x-[calc(var(--arrow-margin))] -translate-y-[calc(var(--arrow-offset)+var(--arrow-visible-size))] before:content-[''] before:absolute before:-z-10 before:top-[var(--arrow-offset)] before:-left-[var(--arrow-visible-size)] before:w-[var(--arrow-size)] before:h-[var(--arrow-size)] before:rotate-45 before:bg-black before:rounded-bl;
  }

  &.bottom {
    @apply -translate-x-[calc(100%-var(--arrow-offset))] translate-y-[calc(var(--arrow-margin))] before:content-[''] before:absolute before:-z-10 before:right-[calc(var(--arrow-offset)-var(--arrow-visible-size))] before:-top-[var(--arrow-visible-size)] before:w-[var(--arrow-size)] before:h-[var(--arrow-size)] before:rotate-45 before:bg-black before:rounded-tl;
  }

  &.left {
    @apply -translate-x-[calc(100%+var(--arrow-margin))] -translate-y-[calc(var(--arrow-offset)+var(--arrow-visible-size))] before:content-[''] before:absolute before:-z-10 before:top-[var(--arrow-offset)] before:-right-[var(--arrow-visible-size)] before:w-[var(--arrow-size)] before:h-[var(--arrow-size)] before:rotate-45 before:bg-black before:rounded-tr;
  }
}
