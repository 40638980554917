.toggle__switch {
  &-trigger {
    @apply flex justify-between items-center gap-2;

    > :last-child {
      @apply grow flex items-center gap-2;
    }

    > :first-child {
      @apply flex-none;
    }

    &-title {
      @apply h4 text-secondary;
    }

    &-snippet {
      @apply text-gray-2 mt-2;
    }

    &-text {
      @apply ml-12 font-normal text-md;
    }

    &-arrow {
      @apply w-8 h-8 text-secondary fill-current first:block last:hidden;
    }

    [role="button"][aria-expanded="true"] &-arrow {
      @apply first:hidden last:block;
    }
  }

  &-toggle {
    @apply relative inline-block w-full rounded-full cursor-pointer;

    > :first-child {
      @apply h-6 relative;
    }

    input {
      @apply opacity-0 w-0 h-0;
    }

    &-content {
      @apply absolute top-0 left-0 right-0 bottom-0 bg-alert/10 rounded-full before:content-[''] before:absolute before:w-5 before:h-5 before:bottom-0.5 before:left-0.5 before:rounded-full before:bg-alert before:transition before:duration-300 w-14;
    }

    input:focus ~ &-content {
      @apply outline outline-alert outline-offset-2 transition-none;
    }

    input:focus:checked ~ &-content {
      @apply outline-success;
    }

    input:checked ~ &-content {
      @apply bg-success before:bg-white before:translate-x-8;
    }

    input:disabled ~ &-content {
      @apply bg-background-3 cursor-not-allowed before:bg-gray before:border before:border-gray;
    }

    &-icon {
      @apply absolute top-1/2 -translate-y-1/2 w-4 h-4 fill-current first-of-type:text-white last-of-type:text-alert first-of-type:hidden last-of-type:block first-of-type:left-2 last-of-type:left-9 w-auto;
    }

    input:checked ~ &-icon {
      @apply first-of-type:block last-of-type:hidden;
    }

    input:disabled ~ &-icon {
      @apply text-gray cursor-not-allowed;
    }
  }
}
