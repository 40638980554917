.form-defaults {
  /* text-like inputs */
  input[type="date"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select,
  textarea {
    &:not(.reset-defaults) {
      @apply inline-block px-4 py-2 border border-gray outline outline-1 outline-transparent rounded bg-background-2 text-black font-normal placeholder:text-gray focus:outline-2 focus:outline-secondary disabled:bg-background-3 disabled:text-gray-2 disabled:cursor-not-allowed;

      &.sm {
        @apply px-1 py-0.5 text-sm;
      }

      &.sm-icon {
        @apply pr-1 pl-6 py-0.5 text-sm;
      }

      &.is-invalid-input:not(:focus) {
        @apply outline-2 outline-alert;
      }
    }

    /* read-only can be applied only to input and textarea, otherwise selects will be set as readonly */
    &:not(select) {
      @apply read-only:bg-background read-only:border-0 read-only:text-gray-2;
    }
  }

  /* option-like inputs */
  input[type="checkbox"],
  input[type="radio"] {
    &:not(.reset-defaults) {
      @apply inline-block mr-2;
    }
  }

  select {
    &:not(.reset-defaults) {
      @apply pr-8;
    }

    @apply appearance-none;

    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 8'%3e%3cpath fill='%233E4C5C' d='M5.99962 4.97656L10.1246 0.851562L11.303 2.0299L5.99962 7.33323L0.696289 2.0299L1.87462 0.851562L5.99962 4.97656Z'/%3e%3c/svg%3e");
    background-position: right 1rem center;
    background-repeat: no-repeat;
    background-size: 0.75rem;
  }
}

.form-error {
  @apply mt-2 hidden font-normal text-sm text-alert;
}

.form-error.is-visible {
  @apply block;
}

.help-text {
  @apply block text-sm text-gray-2 font-normal prose-a:text-secondary prose-a:underline;
}

.form__wrapper {
  @apply flex flex-col py-10 gap-10 last:pb-0;

  &-block {
    @apply py-10 last:pb-0 border-t-2 border-background flex flex-col gap-4 only:[&_*]:ml-auto [&_p]:text-gray-2;
  }

  &-checkbox-label {
    @apply text-md text-gray-2 font-normal prose-a:text-secondary prose-a:underline cursor-pointer;

    & + & {
      @apply block;
    }
  }

  & label:not(&-checkbox-label),
  &-block label:not(&-checkbox-label) {
    @apply font-semibold text-lg;
  }

  .help-text {
    @apply mt-4;
  }

  input[type="date"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select,
  textarea {
    @apply w-full block mt-4;
  }

  input[type="checkbox"],
  input[type="radio"] {
    @apply mt-4;
  }
}

.input-character-counter {
  @apply relative mt-4 block;

  input[type="date"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select,
  textarea {
    @apply mt-0;
  }

  &__container {
    @apply -translate-y-full;
  }

  &__text {
    @apply font-normal inline-block text-xs text-gray-2 float-right mt-1;
  }
}

.input-group__password {
  @apply w-full relative;

  input {
    @apply w-full;
  }

  button {
    @apply absolute right-2 top-8;
  }
}

.emoji {
  &__container {
    @apply relative;
  }

  &__trigger {
    @apply relative block;
  }

  &__button {
    @apply absolute right-4 bottom-4 cursor-pointer text-gray-2;
  }

  &__button svg {
    width: 1em;
  }

  &__reference {
    @apply block absolute bottom-0 right-0;
  }

  &__decidim {
    @apply max-w-full z-50;
  }
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.autoComplete_wrapper {
  display: block;
}

.old-password__wrapper {
  @apply p-10 border-2 border-solid border-secondary rounded;

  background-color: rgb(var(--secondary-rgb) / 0.05);
}
