.login {
  &__info {
    @apply text-gray-2 text-lg space-y-4 mb-10 prose-a:text-secondary prose-a:underline;
  }

  &__info-required {
    @apply mb-10 text-gray-2 text-sm;
  }

  &__info-separator {
    @apply border border-background;
  }

  &__omniauth {
    @apply flex flex-col items-center gap-8 text-gray-2 font-semibold w-fit mx-auto mt-10;

    &-button {
      @apply button button__xl border border-gray shadow-[0_4px_6px_rgba(211,211,211,0.25)] justify-start gap-8 text-lg w-full;

      svg {
        @apply w-6 h-6 fill-current flex-none;
      }
    }

    &-separator {
      @apply mt-10 flex items-center justify-center gap-12 after:content-[''] before:content-[''] after:h-px before:h-px after:bg-background before:bg-background after:grow before:grow;
    }

    &__horizontal {
      @apply flex-row flex-wrap [&>a]:w-auto;
    }
  }

  &__links {
    @apply flex flex-col gap-4 py-10 border-t-2 border-background;

    a {
      @apply w-fit text-secondary hover:underline;
    }
  }

  &__modal {
    &-links {
      @apply flex flex-col md:flex-row gap-4 justify-between items-start md:items-center [&>a]:text-secondary;
    }

    [data-announcement] {
      @apply mt-4;
    }
  }

  &__modal &__omniauth {
    @apply mt-6;

    &-separator {
      @apply last:mt-0 last:invisible;
    }
  }
}
