.endorsers-list {
  @apply space-y-3 opacity-0 -translate-y-full invisible transition-all absolute;

  &__text {
    @apply text-sm text-gray-2;
  }

  &__grid {
    @apply flex flex-wrap gap-y-3;
  }

  &__avatar,
  &__counter {
    @apply rounded-full overflow-hidden border border-white inline-grid w-6 h-6;
  }

  &__counter {
    @apply bg-background place-items-center text-secondary text-xs font-semibold transition;
  }

  &__button {
    @apply block ml-auto text-right text-xs text-secondary cursor-pointer;
  }

  &__trigger {
    @apply flex justify-start -space-x-4;
  }

  &__trigger:hover &__counter,
  &__trigger[aria-expanded="true"] &__counter {
    @apply text-white bg-secondary border-secondary;
  }

  &__trigger[data-target*="dropdown"] {
    @apply p-0 w-auto;

    > span {
      @apply inline-grid;
    }
  }

  &[id*="dropdown-menu"][aria-hidden="false"] {
    @apply opacity-100 visible translate-y-0 transition-all relative;
  }
}
